.descriptionPanel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;

    &__title {
        font-family: 'BarlowCondensedMedium';
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
    }
    &__desc {
        background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(69, 69, 69, 0.2) 100%);
        border: 1px solid #FEF703;
        border-radius: 5px;
        padding: 16px;
        font-family: 'BarlowThin';
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        width: 100%;
    }
}