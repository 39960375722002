.main-container {
    .homePanel {
        background-image: url('../../assets/images/background.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 40px 50px 40px 20px;
        display: flex;
        flex-direction: row;
        gap: 30px;

        .menu-icon {
            display: none;
            @media(max-width: 1300px){
                display: block;
            }
        }

        @media(max-width: 1200px){
            flex-direction: column;
            padding: 40px 40px 40px 40px;
        }
        @media(max-width: 700px){
            padding: 20px;
        }

        .landListingPanelContainer {
            width: 60%;
            padding-right: 15px;
            @media(max-width: 1600px){
                width: 55%;
            }
            @media(max-width: 1440px){
                width: 50%;
            }
            @media(max-width: 1200px){
                padding-right: unset;
                width: 100%;
            }
        }

        .tradingTrendingPanelContainer {
            width: 40%;
            padding-left: 15px;
            @media(max-width: 1600px){
                width: 45%;
            }
            @media(max-width: 1440px){
                width: 50%;
            }
            @media(max-width: 1200px){
                padding-left: unset;
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 20px;    
                align-items: flex-end;
            }
            @media(max-width: 840px){
                flex-direction: column;
                align-items: center;
            }
        }

        .landListingPanel {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .title1 {
                font-family: 'BarlowBlack';
                font-size: 32px;
                line-height: 38px;
                color: #FEF703;
            }
            .title2 {
                color: white;
            }

            .arrowIconBox {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                gap: 15px;

                &__img {
                    cursor: pointer;
                }
            }

            .characterCardPanel {
                &__box {
                    padding-left: 25px;
                    padding-right: 25px;

                    @media(max-width: 1600px){
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                    @media(max-width: 1440px){
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                    @media(max-width: 1200px){
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                    @media(max-width: 800px){
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }
            }
        }
        .characterListingPanel {
            margin-top: 50px;
        }
    }

    .tradingViewPanel {
        width: 100%;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        padding: 20px;

        @media(max-width: 840px){
            width: 520px;
            margin: auto;
        }
        @media(max-width: 540px){
            width: 100%;
        }

        &__box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .panel {
                display: flex;
                flex-direction: row;
                gap: 10px;
                justify-content: flex-start;
                align-items: center;

                .box {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;

                    .title1 {
                        font-family: 'BarlowBold';
                        font-size: 12px;
                        text-align: center;
                        color: #FFFFFF;
                    }
                    .title2 {
                        font-family: 'BarlowMedium';
                        font-size: 14px;
                        text-align: center;
                        color: #FFFFFF;
                    }
                }
            }
        }
        &__title {
            margin-top: 15px;
            font-family: 'BarlowMedium';
            font-size: 12px;
            line-height: 14px;
            color: #FFFFFF;
        }
        &__bottomBox {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__customSelect {
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                gap: 12px;
                align-items: center;
                justify-content: flex-start;

                select {
                    background: rgba(0, 0, 0, 0.8);
                    border-radius: 20px;
                    width: 100px;
                    // height: 36px;
                    font-family: 'BarlowMedium';
                    font-size: 18px;
                    line-height: 22px;
                    padding: 3px 13px;
                    color: white;
                    cursor: pointer;
                    // appearance: none;
                    outline: 0;
                }
                
                select > option {
                    padding: 7px 13px !important;
                    font-size: 18px;
                    background: rgba(0, 0, 0, 0.8);
                }
            }
            &__duringSelect {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;

                &__title {
                    font-family: 'BarlowMedium';
                    font-size: 12px;
                    text-align: center;
                    color: #C4C4C4;
                    padding: 2px 5px;
                    background: rgba(0, 0, 0, 0.5);
                    border-radius: 30px;
                }
            }
        }
    }

    .trendingNFTsPanel {
        margin-top: 50px;
        width: 100%;
        @media(max-width: 840px){
            width: 520px;
            margin: auto;
        }
        @media(max-width: 540px){
            width: 100%;
        }
        .title1 {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-family: 'BarlowBlack';
            font-size: 32px;
            line-height: 38px;
            color: #FEF703;
        }
        .title2 {
            color: white;
        }
        .box {
            margin-top: 50px;
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid #FFFFFF;
            border-radius: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            // gap: 10px;
            padding: 40px 20px;

            .boxLeft {
                width: 50%;
            }
            .boxRight {
                width: 50%;
                display: flex;
                flex-direction: column;

                .firstBox {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    &__title {
                        font-family: 'BarlowMedium';
                        font-size: 16px;
                        line-height: 17px;
                        color: #FFFFFF;
                        @media(max-width: 991px){
                            font-size: 13px;
                        }
                    }
                    &__icon {
                        font-size: 14px;
                        color: #FFFFFF;
                    }
                }
                .secondBox {
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    justify-content: flex-start;

                    .title1 {
                        font-family: 'BarlowRegular';
                        font-size: 13px;
                        line-height: 12px;
                        color: #FFFFFF;
                        flex-direction: row;
                        justify-content: flex-start;
                    }
                    .bottomPanel {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;

                        &__text {
                            font-family: 'BarlowMedium';
                            font-size: 14px;
                            line-height: 14px;
                            text-align: center;
                            color: #FFFFFF;
                        }
                    }
                }
                .thirdBox {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    padding: 9px 11px;
                    background: rgba(0, 0, 0, 0.3);
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;

                    &__first {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        &__title {
                            font-family: 'BarlowRegular';
                            line-height: 12px;
                            text-align: center;
                            font-family: 'BarlowRegular';
                            font-size: 13px;
                            color: white;
                        }
                    }
                    &__second {
                        margin-top: 12px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        &__title {
                            font-family: 'BarlowBold';
                            line-height: 12px;
                            text-align: center;
                            font-size: 16px;
                            color: white;
                        }
                    }
                    &__third {
                        margin-top: 30px;
                        &__img {
                            margin-left: -5px;
                        }
                    }
                }
                .fourthBox {
                    margin-top: 25px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    &__title1 {
                        font-family: 'BarlowBold';
                        font-size: 16px;
                        line-height: 14px;
                        color: #08070C;
                        background: #FEF703;
                        border-radius: 10px;
                        padding: 14px 12px;
                        @media(max-width: 991px){
                            padding: 8px 6px;
                            font-size: 14px;
                        }
                    }

                    &__title2 {
                        font-family: 'BarlowBold';
                        font-size: 16px;
                        line-height: 14px;
                        color: white;
                        @media(max-width: 991px){
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}