.detailItem {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    position: relative;

    background-image: url('../../assets/images/background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 40px 50px 40px 20px;

    @media(max-width: 1300px){
        // flex-direction: column;
        padding: 40px 40px 40px 40px;
    }
    @media(max-width: 700px){
        padding: 20px;
    }

    .menu-icon {
        display: none;
        @media(max-width: 1300px){
            display: block;
        }
    }

    &__img {
        position: absolute;
        right: 30px;
        top: 0;

        @media(max-width: 920px){
            position: unset;
            margin: auto;
            margin-top: -70px;
            margin-bottom: -70px;
            // right: 30px;
            // top: 0;
        }
    }

    .title {
        font-family: 'barlowCondensedMedium';
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        text-transform: uppercase;
    }

    .itemBox {
        display: flex;
        flex-direction: column;

        .topBox {
            display: flex;
            flex-direction: row;
            gap: 20px;
            justify-content: flex-start;
            align-items: flex-start;

            background-image: url('../../assets/images/detail/back.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding: 13px 18px;

            &__right {
                display: flex;
                flex-direction: column;
                gap: 15px;
                width: 65%;

                @media(max-width: 1600px){
                    width: 50%;
                }
                @media(max-width: 920px){
                    width: 100%;
                }

                &__firstRow {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    justify-content: flex-start;
                    align-items: center;

                    &__title1 {
                        font-family: 'barlowCondensedMedium';
                        font-size: 24px;
                        line-height: 29px;
                        color: #FFFFFF;
                        text-transform: uppercase;
                    }
                    &__title2 {
                        font-family: 'barlowCondensedRegular';
                        font-size: 12px;
                        line-height: 11px;
                        text-align: center;
                        color: #FFBE4D;
                        text-transform: uppercase;
                        padding: 2px 7px;
                        background: #18171F;
                        border: 1px solid #FFBE4D;
                        border-radius: 50px;
                    }
                }
                &__secondRow {

                    &__title {
                        font-family: 'barlowRegular';
                        font-size: 15px;
                        line-height: 17px;
                        color: #FFFFFF;
                    }
                }
                &__thirdRow {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 13px;

                    &__createBtn {
                        font-family: 'barlowCondensedBold';
                        font-size: 16px;
                        line-height: 17px;
                        text-align: center;
                        color: #FEF703;
                        background: rgba(254, 247, 3, 0.2);
                        border-radius: 5px;
                        padding: 8px 27px;
                        text-transform: uppercase;
                    }

                    &__rightPanel {
                        display: flex;
                        flex-direction: row;
                        gap: 24px;
                        justify-content: flex-start;
                        align-items: center;

                        &__customSelect {
                            select {
                                background: rgba(254, 247, 3, 0.2);
                                border-radius: 5px;
                                width: 114px;
                                // height: 36px;
                                font-family: 'BarlowCondensedMedium';
                                font-size: 18px;
                                line-height: 22px;
                                padding: 7px 13px;
                                color: #FEF703;
                                cursor: pointer;
                                // appearance: none;
                                outline: 0;
                            }
                            
                            select > option {
                                padding: 7px 13px !important;
                                font-size: 18px;
                                background: #484816;
                            }
                        }
                    }
                }
            }
        }
    }
    .detailSkillBox {
        display: flex;
        flex-direction: row;
        gap: 30px;
        justify-content: flex-start;
        width: 70%;
        margin-bottom: 40px;

        @media(max-width: 920px){
            width: 100%;
        }


        .detailBox {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 50%;

            &__title {
                font-family: 'BarlowCondensedMedium';
                font-size: 13px;
                line-height: 13px;
                color: #929296;
                padding-bottom: 12px;
                text-transform: uppercase;

                border-bottom: 1px solid #2D2B32;
            }
        }
        .skillBox {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 50%;

            &__title {
                font-family: 'BarlowCondensedMedium';
                font-size: 13px;
                line-height: 13px;
                color: #929296;
                padding-bottom: 12px;
                text-transform: uppercase;

                border-bottom: 1px solid #2D2B32;
            }
        }
    }

    .sellersPanel {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        &__topBox {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 15px;
            border-bottom: 1px solid #FEF703;


            &__title {
                font-family: 'BarlowCondensedMedium';
                font-size: 32px;
                line-height: 38px;
                text-align: center;
                color: #FFFFFF;
                text-transform: uppercase;
            }

            &__rightIconPanel {
                display: flex;
                flex-direction: row;
                gap: 15px;
                justify-content: flex-start;
                align-items: center;
            }
        }
        &__sellerBox {
            margin-top: 50px;
        }
    }
}

.detailSkillBox__table {
    margin-top: 20px;
}

.th_text {
    font-family: 'BarlowCondensedLight';
    font-size: 13px;
    line-height: 13px;
    color: #929296;
    padding-bottom: 12px;
    text-transform: uppercase;
}

.td_text {
    font-family: 'BarlowCondensedMedium';
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    text-transform: capitalize;
}

.width-40 {
    width: 40%;
}
.width-30 {
    width: 30%;
}