body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* custom css */
.nav-tabs {
  border-bottom: 1px solid #FEF703;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  font-family: 'BarlowCondensedMedium';
  font-size: 16px;
  /* line-height: 19px; */
  text-align: center;
  color: #1A1C1B;
  padding: 15px 60px;
  background-color: #FEF703;
  border: none;
}

.nav-tabs .nav-link {
  font-family: 'BarlowCondensedMedium';
  font-size: 16px;
  /* line-height: 19px; */
  text-align: center;
  color: #FEF703;
  padding: 15px 60px;
  border: none;
}

table.dataTable {
  border: none;
  background-color: #FEF703 !important;
  min-width: 670px;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #FEF703 !important;
}

.dataTables_info {
  display: none;
}

table.dataTable thead {
  border-top: 1px solid #FEF703 !important;
}

table.dataTable thead tr {
  border-top: 1px solid #FEF703 !important;
  background: #484816;
  height: 50px;
}

table.dataTable thead tr th {
  border-top: 1px solid #FEF703 !important;
  font-family: 'BarlowBold';
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-transform: uppercase;
  vertical-align: middle;
}

table.dataTable tbody tr.odd td {
  border-top: 1px solid #FEF703;
  background: #1a1c1b !important;
  height: 50px;
}

table.dataTable tbody tr.even td {
  border-top: 1px solid #FEF703;
  background: #1a1c1b !important;
  height: 50px;
}

table.dataTable tbody tr td {
  font-family: 'BarlowMedium';
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-transform: uppercase;
  vertical-align: middle;
}

/* barlow-condensed font */

@font-face {
  font-family: "barlowCondensedBold";
  src: url("./assets/fonts/barlow-condensed/barlow-condensed.bold.ttf");
}

@font-face {
  font-family: "barlowCondensedMedium";
  src: url("./assets/fonts/barlow-condensed/barlow-condensed.medium.ttf");
}

@font-face {
  font-family: "barlowCondensedRegular";
  src: url("./assets/fonts/barlow-condensed/barlow-condensed.regular.ttf");
}

@font-face {
  font-family: "barlowCondensedLight";
  src: url("./assets/fonts/barlow-condensed/barlow-condensed.light.ttf");
}

/* barlow font */

@font-face {
  font-family: "BarlowThin";
  src: url("./assets/fonts/Barlow/Barlow-Thin.ttf");
}


@font-face {
  font-family: "BarlowRegular";
  src: url("./assets/fonts/Barlow/Barlow-Regular.ttf");
}

@font-face {
  font-family: "BarlowMedium";
  src: url("./assets/fonts/Barlow/Barlow-Medium.ttf");
}

@font-face {
  font-family: "BarlowSemiBold";
  src: url("./assets/fonts/Barlow/Barlow-SemiBold.ttf");
}

@font-face {
  font-family: "BarlowBold";
  src: url("./assets/fonts/Barlow/Barlow-Bold.ttf");
}

@font-face {
  font-family: "BarlowBlack";
  src: url("./assets/fonts/Barlow/Barlow-Black.ttf");
}