.sellerCard {
    background: linear-gradient(180deg, rgba(69, 69, 69, 0) 0%, rgba(69, 69, 69, 0.2) 100%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 120px;
    padding-bottom: 10px;
    margin: auto;

    &__title {
        font-family: 'BarlowMedium';
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: #FFFFFF;
        padding: 8px 16px;
        background: rgba(254, 247, 3, 0.1);
        border-radius: 5px 5px 0px 0px;
    }
    &__img {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &__walletText {
        margin-top: 10px;
        font-family: 'BarlowMedium';
        font-size: 13px;
        line-height: 12px;
        text-align: center;
        color: #FFFFFF;
    }
    &__quantityBox {
        padding: 10px 7px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &__title1 {
            font-family: 'BarlowThin';
            font-size: 13px;
            line-height: 12px;
            color: #FFFFFF;
            text-transform: capitalize;
        }
        &__title2 {
            font-family: 'BarlowMedium';
            font-size: 13px;
            line-height: 12px;
            color: #FFFFFF;
        }
    }
}