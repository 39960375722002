.listItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;

    background-image: url('../../assets/images/background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 40px 50px 40px 20px;

    @media(max-width: 1300px){
        padding: 40px 40px 40px 40px;
    }
    @media(max-width: 700px){
        padding: 20px;
    }

    .menu-icon {
        display: none;
        @media(max-width: 1300px){
            display: block;
        }
    }

    .title {
        font-family: 'barlowCondensedMedium';
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        text-transform: uppercase;
    }
    .titleHeader1 {
        font-family: 'BarlowBold';
        font-size: 32px;
        line-height: 38px;
        color: #FEF703;
        padding-bottom: 15px;
        border-bottom: 1px solid #FEF703;
    }
    .titleHeader2 {
        color: white;
    }
    .cardBox {
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        @media(max-width: 1100px){
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
        @media(max-width: 1000px){
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        @media(max-width: 750px){
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &__panel {
            margin-bottom: 40px;
            margin-left: 20px;
            margin-right: 20px;

            @media(max-width: 1200px){
                margin-bottom: 20px;
                margin-left: 10px;
                margin-right: 10px;
            }
            @media(max-width: 1100px){
                margin-bottom: 40px;
                margin-left: 20px;
                margin-right: 20px;
            }
            @media(max-width: 560px){
                margin-bottom: 10px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
}