.characterCard {
    background: #08070C;
    border: 1px solid #FEF703;
    border-radius: 20px;
    padding: 18px 0px 12px;
    display: flex;
    flex-direction: column;
    // margin-left: 25px;
    // margin-right: 25px;

    .titleBox {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 20px;

        &__title1 {
            font-family: 'barlowCondensedLight';
            font-size: 16px;
            line-height: 17px;
            text-align: center;
            color: #FFFFFF;
        }

        &__title2 {
            font-family: 'barlowCondensedLight';
            font-size: 14px;
            line-height: 11px;
            text-align: center;
            color: #FFBE4D;
            border: 1px solid #FFBE4D;
            border-radius: 50px;
            padding: 3px 7px;
        }
    }

    &__imgPanel {
        padding: 0px 20px 5px 20px;

        &__img {
            width: 100%;
        }
    }

    .viewItem {
        padding: 0 20px;
        cursor: pointer;

        &__text {
            padding: 6px 0;
            background: rgba(254, 247, 3, 0.2);
            border-radius: 5px;
            font-family: 'barlowCondensedBold';
            font-size: 18px;
            line-height: 19px;
            color: #FEF703;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
}