.iconBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #0E0F13;
    padding: 10px;
    border-radius: 10px;

    &__img {
        width: 35px;
        height: 25px;
    }

    &__title {
        margin-top: 5px;
        font-family: 'barlowCondensedBold';
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #33383C;
    }
}