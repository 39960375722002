.myProfile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;

    background-image: url('../../assets/images/background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 40px 50px 40px 20px;

    .menu-icon {
        display: none;
        @media(max-width: 1300px){
            display: block;
        }
    }

    @media(max-width: 1300px){
        padding: 40px 40px 40px 40px;
    }
    @media(max-width: 700px){
        padding: 20px;
    }

    .title {
        font-family: 'barlowCondensedMedium';
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        text-transform: uppercase;
    }
    .titleHeader1 {
        font-family: 'BarlowBold';
        font-size: 32px;
        line-height: 38px;
        color: #FEF703;
        padding-bottom: 15px;
        border-bottom: 1px solid #FEF703;
        text-transform: capitalize;
    }
    .titleHeader2 {
        color: white;
    }

    &__box {
        display: flex;
        flex-direction: row;
        gap: 50px;

        @media(max-width: 650px){
            flex-direction: column;
        }

        &__left {
            width: 422px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 35px 70px;
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid #FFFFFF;
            border-radius: 20px;

            @media(max-width: 791px){
                padding: 35px;
                width: 350px;
            }
            @media(max-width: 650px){
                margin: auto;
            }

            &__img {

            }
            &__title1 {
                margin-top: 25px;
                font-family: 'BarlowCondensedRegular';
                font-size: 12px;
                line-height: 11px;
                text-align: center;
                color: #FFBE4D;

                background: #18171F;
                border: 1px solid #FFBE4D;
                border-radius: 50px;
                padding: 2px 10px;
                text-transform: uppercase;
            }
            &__title2 {
                margin-top: 33px;
                width: 100%;
                font-family: 'BarlowCondensedMedium';
                font-size: 14px;
                line-height: 13px;
                color: #929296;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }
            &__title3 {
                margin-top: 10px;
                font-family: 'BarlowCondensedMedium';
                font-size: 36px;
                line-height: 43px;
                text-align: center;
                color: #FFFFFF;
            }
        }
        &__right {
            width: calc(100% - 422px);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            @media(max-width: 650px){
                width: 100%;
                // flex-direction: row;
                // gap: 10px;
            }

            &__title1 {
                font-family: 'BarlowCondensedMedium';
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #FFFFFF;
            }
            &__title2 {
                margin-top: 10px;
                font-family: 'BarlowThin';
                font-size: 14px;
                line-height: 17px;
                color: #FFFFFF;
            }
            &__title3 {
                margin-top: 50px;
                font-family: 'BarlowCondensedMedium';
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #FFFFFF;
            }
            &__iconBox {
                margin-top: 15px;
                display: flex;
                flex-direction: row;
                gap: 24px;
                justify-content: flex-start;
                align-items: center;
                
                &__title {
                    font-family: 'BarlowMedium';
                    font-size: 14px;
                    line-height: 17px;
                    color: #FFFFFF;
                }
            }
        }
    }

    &__tabs {
        margin-top: 54px;
        .descriptionPanel {
            @media(max-width: 767px){
                margin-top: 10px;
            }
        }
        .updateProfileBtn {
            margin-top: 15px;
            padding: 13px 19px;
            font-family: 'BarlowMedium';
            font-size: 14px;
            line-height: 14px;
            color: #08070C;
            background: #FEF703;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;
            float: right;
            cursor: pointer;
        }

        .cardBox {
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            @media(max-width: 1100px){
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
            @media(max-width: 1000px){
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
            @media(max-width: 750px){
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
    
            &__panel {
                margin-bottom: 40px;
                margin-left: 20px;
                margin-right: 20px;
                @media(max-width: 1200px){
                    margin-bottom: 20px;
                    margin-left: 10px;
                    margin-right: 10px;
                }
                @media(max-width: 1100px){
                    margin-bottom: 40px;
                    margin-left: 20px;
                    margin-right: 20px;
                }
                @media(max-width: 560px){
                    margin-bottom: 10px;
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
        }
    }
}