.left-side-bar {
    .brand-logo {
        .logoImg {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 40px;
        }
    }

    .iconBoxPanel {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        padding: 20px 20px;
        gap: 10px;
    }

    .menu-block {
        .sidebar-menu {
            padding: 0 20px;
        }
    }

    .leftSideButtonPanel {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 20px;
        margin-bottom: 25px;
    }
}