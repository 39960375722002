.myOrdersPanel {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    height: 100vh;

    background-image: url('../../assets/images/background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 40px 50px 40px 20px;

    .menu-icon {
        display: none;
        @media(max-width: 1300px){
            display: block;
        }
    }

    @media(max-width: 1300px){
        padding: 40px 40px 40px 40px;
    }
    @media(max-width: 700px){
        padding: 20px;
    }

    .title {
        font-family: 'barlowCondensedMedium';
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        text-transform: uppercase;
    }
    .titleHeader1 {
        font-family: 'BarlowBold';
        font-size: 32px;
        line-height: 38px;
        color: #FEF703;
        padding-bottom: 15px;
        border-bottom: 1px solid #FEF703;
        text-transform: capitalize;
    }
    .titleHeader2 {
        color: white;
    }

    &__tabs {
        margin-top: 10px;
    }
}
