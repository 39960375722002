.leftButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
    background-color: #0E0F13;
    border-radius: 10px;
    cursor: pointer;

    &__img {
        
    }

    &__label {
        font-family: 'barlowCondensedBold';
        font-size: 18px;
        line-height: 22px;
        color: #33383C;
        padding: 14px 0px;
    }
}